<template>
    <div>
        <appic-page-title-bar ref="breadcrumbBar"/>
        <app-section-loader :status="loader"/>
        <v-container fluid grid-list-xl pb-0 pt-3 px-3>
            <v-layout row wrap>
                <app-card
                    :fullBlock="true"
                    colClasses="xl12 lg12 md12 sm12 xs12"
                >
                    <v-row no-gutters class="pt-3 pl-0 pb-3" dense>
                        <v-col cols="12" class="d-flex flex-row align-center">
                            <v-select
                                :items="years"
                                :label="$t('message.year')"
                                :style="'max-width: 150px'"
                                dense
                                hide-details="auto"
                                solo
                                v-model="filters.year"
                            />
                            <v-select
                                :items="months"
                                :placeholder="$t('message.allMonths')"
                                :style="'max-width: 150px'"
                                class="ml-3"
                                dense
                                hide-details="auto"
                                item-text="Month.code"
                                item-value="Month.id"
                                solo
                                v-model="filters.month"
                            />
                            <v-select
                                :items="filteredOffices"
                                :label="$t('message.company')"
                                :style="'max-width: 100px'"
                                class="ml-3"
                                dense
                                hide-details="auto"
                                item-value="Office.id"
                                item-text="Office.code"
                                solo
                                v-model="filters.office"
                            />
                            <v-select
                                :items="visibilityOptions"
                                :placeholder="$t('message.allEntries')"
                                :style="'max-width: 300px'"
                                class="ml-3"
                                clearable
                                dense
                                hide-details="auto"
                                solo
                                v-model="filters.visibility"
                            />
                            <v-btn class="ml-3" :loading="loading.items" @click="getList">{{ $t('message.fetch') }}</v-btn>
                            <v-spacer/>
                            <v-switch
                                :true-value="true"
                                :false-value="false"
                                :label="$t('message.scrollingList')"
                                class="ml-0 font-sm"
                                dense
                                hide-details="auto"
                                v-model="scrollingTable"
                            />
                        </v-col>
                    </v-row>
                    <v-overlay
                        :value="loading.items"
                        absolute
                        opacity="0.15"
                    >
                        <v-row>
                            <v-col class="text-center">
                                <v-progress-circular
                                    color="primary"
                                    indeterminate
                                    size="40"
                                    width="6"
                                />
                            </v-col>
                        </v-row>
                    </v-overlay>
                    <v-data-table
                        :fixed-header="scrollingTable"
                        :footer-props="{
                            itemsPerPageOptions: [20,50,75,-1],
                            showCurrentPage: true,
                            showFirstLastPage: true
                        }"
                        :headers="headers"
                        :height="scrollingTable ? tableHeight : null"
                        :hide-default-footer="true"
                        :items-per-page="-1"
                        :items="items"
                        :search="searchTerm"
                        :sort-by="'Item.Appic.date'"
                        calculate-widths
                        class="appic-table-light specification-table"
                        dense
                        id="comparisonTable"
                        item-key="Contract.id"
                    >
                        <template v-slot:top>
                            <v-row no-gutters>
                                <v-col cols="12" class="d-flex flex-row align-center mb-3">
                                    <v-text-field
                                        :label="$t('message.filterResults') + ' (' + $t('message.pressEnterToSearch') + ')'"
                                        :style="'max-width: 350px'"
                                        :value="searchTerm"
                                        autocomplete="off"
                                        dense
                                        hide-details="auto"
                                        prepend-inner-icon="mdi-filter-outline"
                                        solo
                                        @change="searchTerm = $event"
                                    />
                                    <v-spacer/>
                                    <ExportTableJson
                                        :export-conditions="[]"
                                        :export-data="items"
                                        :export-fields="headers"
                                        :export-source="'appic-xero-comparison'"
                                    />
                                </v-col>
                            </v-row>
                        </template>
                        <template v-slot:item.Item.invoiceNumber="{ item }">
                            <span class="font-weight-bold">{{ item.Item.invoiceNumber }}</span>
                        </template>
                        <template v-slot:item.Item.Appic.source="{ item }">
                            <template v-if="item.Item.Appic.source != null">{{ item.Item.Appic.source }}</template>
                            <template v-else>
                                <div class="no-appic-record red--text text--darken-3 font-weight-bold">{{ $t('message.noAppicRecord') }}</div>
                            </template>
                        </template>
                        <template v-slot:item.Item.Appic.date="{ item }">
                            {{ item.Item.Appic.date != '0000-00-00' ? formatDate(item.Item.Appic.date) : '' }}
                        </template>
                        <template v-slot:item.Item.Appic.amount="{ item }">
                            <span class="text-right" v-if="item.Item.Appic.amount != null">{{ formatThisNumber(item.Item.Appic.amount,'0,0.00') }}</span>
                        </template>
                        <template v-slot:item.Item.Appic.localAmount="{ item }">
                            <span class="text-right" v-if="item.Item.Appic.localAmount != null">{{ formatThisNumber(item.Item.Appic.localAmount,'0,0.00') }}</span>
                        </template>
                        <template v-slot:item.Item.Appic.tax="{ item }">
                            <span v-if="item.Item.Appic.tax != null">{{ item.Item.Appic.tax  + '%' }}</span>
                        </template>
                        <template v-slot:item.Item.Xero.source="{ item }">
                            <template v-if="item.Item.Xero.source != null">{{ item.Item.Xero.source }}</template>
                            <template v-else>
                                <div class="no-appic-record red--text text--darken-3 font-weight-bold">{{ $t('message.noXeroRecord') }}</div>
                            </template>
                        </template>
                        <template v-slot:item.Item.Xero.journalDate="{ item }">
                            {{ item.Item.Xero.journalDate != '0000-00-00' ? formatDate(item.Item.Xero.journalDate) : '' }}
                        </template>
                        <template v-slot:item.Item.Xero.invoiceTotal="{ item }">
                            <span class="text-right" v-if="item.Item.Xero.invoiceTotal != null">{{ formatThisNumber(item.Item.Xero.invoiceTotal,'0,0.00') }}</span>
                        </template>
                        <template v-slot:item.Item.Xero.invoiceTotalTax="{ item }">
                            <span class="text-right" v-if="item.Item.Xero.invoiceTotalTax != null">{{ formatThisNumber(item.Item.Xero.invoiceTotalTax,'0,0.00') }}</span>
                        </template>
                        <template v-slot:item.Item.Xero.grossAmount="{ item }">
                            <span class="text-right" v-if="item.Item.Xero.grossAmount != null">{{ formatThisNumber(item.Item.Xero.grossAmount,'0,0.00') }}</span>
                        </template>
                        <template v-slot:item.Item.Xero.netAmount="{ item }">
                            <span class="text-right" v-if="item.Item.Xero.netAmount != null">{{ formatThisNumber(item.Item.Xero.netAmount,'0,0.00') }}</span>
                        </template>
                        <template v-slot:item.Item.Xero.taxAmount="{ item }">
                            <span class="text-right" v-if="item.Item.Xero.taxAmount != null">{{ formatThisNumber(item.Item.Xero.taxAmount,'0,0.00') }}</span>
                        </template>
                        <template v-slot:item.Item.amountDifference="{ item }">
                            <span class="text-right" v-if="item.Item.amountDifference != null">{{ formatThisNumber(item.Item.amountDifference,'0,0.00') }}</span>
                        </template>
                    </v-data-table>
                </app-card>
            </v-layout>
        </v-container>
        <v-dialog
            v-model="creditNoteDialog"
            width="400"
            max-width="400"
            overlay=false
            persistent
        >
            <v-card>
                <v-card-title>
                    <span>{{ $t('message.creditNotes') }}</span>
                </v-card-title>
                <v-card-text>
                    <v-simple-table dense>
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th class="text-start amber lighten-2 pl-1">{{ $t('message.cnNumber') }}</th>
                                    <th class="text-start amber lighten-2 pl-1">{{ $t('message.date') }}</th>
                                    <th class="text-start amber lighten-2 pl-1">{{ $t('message.total') }}</th>
                                    <th class="text-start amber lighten-2 pl-1">{{ $t('message.appliedAmount') }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="item in currentCreditNotes">
                                    <td class="text-start">{{ item.creditNoteNumber }}</td>
                                    <td class="text-start">{{ item.date != '0000-00-00' ? formatDate(item.date) : '' }}</td>
                                    <td class="text-end">{{ formatThisNumber(item.total,'0,0.00') }}</td>
                                    <td class="text-end">{{ formatThisNumber(item.appliedAmount,'0,0.00') }}</td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-card-text>
                <v-card-actions>
                    <v-btn small @click="closeCreditNoteDialog">{{ $t('message.dismiss') }}</v-btn>
                    <v-spacer/>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog
            v-model="overPaymentDialog"
            width="400"
            max-width="400"
            overlay=false
            persistent
        >
            <v-card>
                <v-card-title>
                    <span>{{ $t('message.overPayments') }}</span>
                </v-card-title>
                <v-card-text>
                    <v-simple-table dense>
                        <template v-slot:default>
                            <thead>
                            <tr>
                                <th class="text-start amber lighten-2 pl-1">{{ $t('message.date') }}</th>
                                <th class="text-start amber lighten-2 pl-1">{{ $t('message.total') }}</th>
                                <th class="text-start amber lighten-2 pl-1">{{ $t('message.appliedAmount') }}</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="item in currentOverPayments">
                                <td class="text-start">{{ item.date != '0000-00-00' ? formatDate(item.date) : '' }}</td>
                                <td class="text-end">{{ formatThisNumber(item.total,'0,0.00') }}</td>
                                <td class="text-end">{{ formatThisNumber(item.appliedAmount,'0,0.00') }}</td>
                            </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-card-text>
                <v-card-actions>
                    <v-btn small @click="closeOverPaymentDialog">{{ $t('message.dismiss') }}</v-btn>
                    <v-spacer/>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import {api} from "Api";
import {formatDate, isObject, numberFormat} from "Helpers/helpers";
import ExportTableJson from "Components/Appic/ExportTableJson";
import {parseString} from "@fast-csv/parse";
import {mapFields} from "vuex-map-fields";

export default {
    name: "AppicXeroComparison",
    components: {ExportTableJson},
    data() {
        return {
            creditNoteDialog: false,
            currentCreditNotes: null,
            currentOverPayments: null,
            filteredOffices: [],
            filters: {
                office: 1,
                year: new Date().getFullYear(),
                // year: 2023,
                month: 1,
                visibility: null
            },
            items: [],
            loader: false,
            loading: {
                filterResults: false,
                search: false,
                items: false
            },
            overPaymentDialog: false,
            scrollingTable: false,
            searchTerm: null,
            tableHeight: '500',
            visibilityOptions: [
                {value: 'appicMissing', text: this.$t('message.itemsMissingInAppic')},
                {value: 'xeroMissing', text: this.$t('message.itemsMissingInXero')},
            ]
        }
    },
    computed: {
        ...mapFields('runtime',{
            openedTabs: 'openedTabs'
        }),
        ...mapGetters([
            'months',
            'offices'
        ]),
        headers() {
            return [
                {
                    id: 1,
                    text: this.$t('message.invoiceDebitNote'),
                    value: 'Item.invoiceNumber',
                    sortable: true,
                    searchable: true,
                    class: 'grey lighten-1 py-0 pr-0 pl-1 font-sm text-left'
                },
                {
                    id: 2,
                    text: this.$t('message.contractSalesOrder'),
                    value: 'Item.Appic.reference',
                    sortable: true,
                    searchable: true,
                    class: 'grey lighten-1 py-0 pr-0 pl-1 font-sm text-left'
                },
                {
                    id: 3,
                    text: this.$t('message.type'),
                    value: 'Item.type',
                    sortable: true,
                    searchable: true,
                    class: 'grey lighten-1 py-0 pr-0 pl-1 font-sm text-left'
                },
                {
                    id: 4,
                    text: this.$t('message.buyer'),
                    value: 'Item.buyer',
                    sortable: true,
                    searchable: true,
                    class: 'grey lighten-1 py-0 pr-0 pl-1 font-sm text-left'
                },
                {
                    id: 5,
                    text: this.$t('message.sales'),
                    value: 'Item.salesContact',
                    sortable: true,
                    searchable: true,
                    class: 'grey lighten-1 py-0 pr-0 pl-1 font-sm text-left'
                },
                {
                    id: 6,
                    text: this.$t('message.source'),
                    value: 'Item.Appic.source',
                    sortable: true,
                    searchable: true,
                    class: 'light-green lighten-3  py-0 pr-0 pl-1 font-sm text-left',
                    cellClass: 'green-cell'
                },
                {
                    id: 7,
                    text: this.$t('message.date'),
                    value: 'Item.Appic.date',
                    sortable: true,
                    searchable: true,
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                    cellClass: 'green-cell'
                },
                {
                    id: 8,
                    text: this.$t('message.amount'),
                    value: 'Item.Appic.amount',
                    sortable: true,
                    searchable: true,
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                    cellClass: 'green-cell text-end'
                },
                {
                    id: 9,
                    text: this.$t('message.cur'),
                    value: 'Item.Appic.currency',
                    sortable: false,
                    searchable: true,
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left width-1-pct',
                    cellClass: 'green-cell text-start'
                },
                {
                    id: 10,
                    text: this.$t('message.invoiceCurrencyRate'),
                    value: 'Item.Appic.currencyRate',
                    sortable: false,
                    searchable: true,
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left width-1-pct',
                    cellClass: 'green-cell text-start'
                },
                {
                    id: 11,
                    text: this.$t('message.localAmount'),
                    value: 'Item.Appic.localAmount',
                    sortable: false,
                    searchable: true,
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left width-1-pct',
                    cellClass: 'green-cell text-end'
                },
                {
                    id: 12,
                    text: this.$t('message.localCurrency'),
                    value: 'Item.Appic.localCurrency',
                    sortable: false,
                    searchable: true,
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left width-1-pct',
                    cellClass: 'green-cell text-start'
                },
                {
                    id: 13,
                    text: this.$t('message.tax'),
                    value: 'Item.Appic.tax',
                    sortable: false,
                    searchable: true,
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left width-1-pct',
                    cellClass: 'green-cell text-start'
                },
                {
                    id: 14,
                    text: this.$t('message.status'),
                    value: 'Item.Appic.status',
                    sortable: true,
                    searchable: true,
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                    cellClass: 'green-cell'
                },
                {
                    id: 15,
                    text: this.$t('message.source'),
                    value: 'Item.Xero.source',
                    sortable: true,
                    searchable: true,
                    class: 'amber py-0 pr-0 pl-1 font-sm text-left',
                    cellClass: 'amber-cell'
                },
                {
                    id: 16,
                    text: this.$t('message.jnlDate'),
                    value: 'Item.Xero.journalDate',
                    sortable: true,
                    searchable: true,
                    class: 'amber py-0 pr-0 pl-1 font-sm text-left',
                    cellClass: 'amber-cell'
                },
                {
                    id: 17,
                    text: this.$t('message.acctCode'),
                    value: 'Item.Xero.accountCode',
                    sortable: true,
                    searchable: true,
                    class: 'amber py-0 pr-0 pl-1 font-sm text-left',
                    cellClass: 'amber-cell'
                },
                {
                    id: 18,
                    text: this.$t('message.acctName'),
                    value: 'Item.Xero.accountName',
                    sortable: true,
                    searchable: true,
                    class: 'amber py-0 pr-0 pl-1 font-sm text-left',
                    cellClass: 'amber-cell'
                },
                {
                    id: 19,
                    text: this.$t('message.invoice'),
                    value: 'Item.Xero.invoiceNumber',
                    sortable: true,
                    searchable: true,
                    class: 'amber py-0 pr-0 pl-1 font-sm text-left',
                    cellClass: 'amber-cell'
                },
                {
                    id: 20,
                    text: this.$t('message.invoiceAmt'),
                    value: 'Item.Xero.invoiceTotal',
                    sortable: true,
                    searchable: true,
                    class: 'amber py-0 pr-0 pl-1 font-sm text-left',
                    cellClass: 'amber-cell text-end'
                },
                {
                    id: 21,
                    text: this.$t('message.invoiceTaxAmt'),
                    value: 'Item.Xero.invoiceTotalTax',
                    sortable: true,
                    searchable: true,
                    class: 'amber py-0 pr-0 pl-1 font-sm text-left',
                    cellClass: 'amber-cell text-end'
                },
                {
                    id: 22,
                    text: this.$t('message.invoiceCurrencyCode'),
                    value: 'Item.Xero.invoiceCurrency',
                    sortable: true,
                    searchable: true,
                    class: 'amber py-0 pr-0 pl-1 font-sm text-left',
                    cellClass: 'amber-cell'
                },
                {
                    id: 23,
                    text: this.$t('message.invoiceCurrencyRate'),
                    value: 'Item.Xero.invoiceCurrencyRate',
                    sortable: true,
                    searchable: true,
                    class: 'amber py-0 pr-0 pl-1 font-sm text-left',
                    cellClass: 'amber-cell text-end'
                },
                {
                    id: 24,
                    text: this.$t('message.reference'),
                    value: 'Item.Xero.reference',
                    sortable: true,
                    searchable: true,
                    class: 'amber py-0 pr-0 pl-1 font-sm text-left',
                    cellClass: 'amber-cell'
                },
                {
                    id: 25,
                    text: this.$t('message.journalGrossAmt'),
                    value: 'Item.Xero.grossAmount',
                    sortable: true,
                    searchable: true,
                    class: 'amber py-0 pr-0 pl-1 font-sm text-left',
                    cellClass: 'amber-cell text-end'
                },
                {
                    id: 26,
                    text: this.$t('message.journalNetAmt'),
                    value: 'Item.Xero.netAmount',
                    sortable: true,
                    searchable: true,
                    class: 'amber py-0 pr-0 pl-1 font-sm text-left',
                    cellClass: 'amber-cell text-end'
                },
                {
                    id: 27,
                    text: this.$t('message.cur'),
                    value: 'Item.Xero.currency',
                    sortable: false,
                    searchable: true,
                    class: 'amber py-0 pr-0 pl-1 font-sm text-left width-1-pct',
                    cellClass: 'amber-cell text-start'
                },
                {
                    id: 28,
                    text: this.$t('message.taxAmt'),
                    value: 'Item.Xero.taxAmount',
                    sortable: false,
                    searchable: false,
                    class: 'amber py-0 pr-0 pl-1 font-sm text-left',
                    cellClass: 'amber-cell text-end'
                },
                {
                    id: 29,
                    text: this.$t('message.taxName'),
                    value: 'Item.Xero.taxName',
                    sortable: false,
                    searchable: false,
                    class: 'amber py-0 pr-0 pl-1 font-sm text-left',
                    cellClass: 'amber-cell text-end'
                },
                {
                    id: 30,
                    text: this.$t('message.amountDifference'),
                    value: 'Item.amountDifference',
                    sortable: true,
                    searchable: true,
                    class: 'red py-0 pr-0 pl-1 font-sm text-left',
                    cellClass: 'red-cell'
                },
            ]
        },
        years(){
            let years = []
            let start = 2024
            let end = new Date().getFullYear();
            let i
            for(i = end; i >= start; i--){
                years.push({value: i, text: i.toString()})
            }
            return years
        }
    },
    methods: {
        closeCreditNoteDialog() {
            this.currentCreditNotes = null
            this.creditNoteDialog = false
        },
        closeOverPaymentDialog() {
            this.currentOverPayments = null
            this.overPaymentDialog = false
        },
        filterOffices() {
            this.filteredOffices = this.offices.filter((office) => {
                return office.Office.id != 3;
            })
        },
        formatThisNumber (value,format) {
            return numberFormat(value,format)
        },
        formatDate(date) {
            return formatDate(date)
        },
        getList() {
            this.loading.items = true
            const conditions = {
                office: this.filters.office,
                year: this.filters.year,
                month: this.filters.month,
                visibility: this.filters.visibility
            }
            api
                .get('/reports/appic-xero-comparison', {
                    params: {
                        conditions: conditions
                    }
                })
                .then((response)=>{
                    if(response.data.status == 'success') {
                        this.items = response.data.data
                    } else {
                        if(isObject(response.data.message)){
                            //extract validation errors
                            const errors = Object.values(response.data.message)
                            errors.forEach(errorObj => {
                                if (Array.isArray(errorObj)) {
                                    errorObj.forEach(error => {
                                        this.$toast.error(error,
                                            {
                                                classes: ['icon-float-left'],
                                                icon: 'error_outline'
                                            }
                                        )
                                    })
                                } else {
                                    this.$toast.error(this.$t('message.errors.listCannotBeLoaded'),
                                        {
                                            classes: ['icon-float-left'],
                                            icon: 'error_outline'
                                        }
                                    )
                                }
                            })
                        } else {
                            this.$toast.error(this.$t('message.errors.listCannotBeLoaded'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                        }
                    }
                    this.loading.items = false
                })
                .catch((error) => {
                    this.loading.items = false
                    this.$toast.error(error,
                        {
                            classes: ['icon-float-left'],
                            icon: 'error_outline'
                        }
                    )
                })
        },
        handleResize() {
            this.tableHeight = window.innerHeight - (230);
        },
        openCreditNoteDialog(creditNotes) {
            this.currentCreditNotes = creditNotes
            this.creditNoteDialog = true
        },
        openOverPaymentDialog(overPayments) {
            this.currentOverPayments = overPayments
            this.overPaymentDialog = true
        },
        parseString,
        reconnectToXero() {
            let tab = window.open(process.env.VUE_APP_XERO_AUTHORIZE_URL)
            tab.name = 'tab_authorize_xero'
            this.openedTabs.push(tab.name)
        },
    },
    watch: {
        scrollingTable(value){
            localStorage.setItem('plToolScrollingTable',value)
            if(value){
                this.handleResize()
            }
        }
    },
    created() {
        // this.isAuthorized = false
        // let _this = this
        //
        // this.$nextTick(function () {
        //     let intervalId = setInterval(function(){
        //         let promise = new Promise((resolve, reject) => {
        //             api
        //                 .get('/xero/appic-authorized')
        //                 .then(response => response.data.status)
        //                 .then(response => resolve(response))
        //                 .catch(error => reject(error))
        //         })
        //         promise
        //             .then(status => {
        //                 if(status == 'success'){
        //                     _this.isAuthorized = true
        //                 }
        //                 clearInterval(intervalId)
        //             })
        //     },2000)
        // })

        if(localStorage.getItem('plToolScrollingTable') !== null){
            this.scrollingTable = localStorage.getItem('plToolScrollingTable') == 'true' ? true : null
        }
        window.addEventListener('resize', this.handleResize)
    },
    mounted() {
        this.handleResize()
        this.getList()
        this.filterOffices()
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize)
    }
}
</script>

<style scoped>
.col-2-5 {
    flex: 0 0 20.83333333%;
    max-width: 20.83333333%;
}
.font-xs {
    font-size: 0.60rem;
}
.v-data-table::v-deep td.text-start {
    padding-left: 3px !important;
    padding-top: 3px !important;
    padding-right: 3px !important;
    vertical-align: middle !important;
}
.truncate {
    display: inline-block;
    width: 100px !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.v-data-table::v-deep td {
    padding: 0 2px;
    font-size: 0.8rem !important;
    line-height: 0.8rem;
}
.v-data-table::v-deep th span {
    font-family: 'Arial Narrow', Arial, SansSerif;
    font-size: 0.8rem !important;
    font-weight: normal;
    line-height: 0.8rem;
    font-weight: bold !important;
}
.v-data-table::v-deep th {
    white-space: nowrap;
}
.v-data-table::v-deep .green-cell {
    background-color: #d6f5b3;
}
.v-data-table::v-deep .amber-cell {
    background-color: #f8d17a;
}
.v-data-table::v-deep .red-cell {
    background-color: #eeaeb7;
}
.no-appic-record {
    position: relative;
    left: 150px;
}
.no-xero-record {
    position: relative;
    left: 150px;
}
.credit-notes-table th {
    border-collapse: unset;
    border-width: thin;
}
</style>